import React from 'react';
import { CircularProgress as CircularProgressMui, Typography, Box } from '@material-ui/core';

export default function CircularProgress({ current = 1, total = 10 }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgressMui variant="determinate" size={50} value={(current / total) * 100} />
            <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">
                    {`${current}/${total}`}
                </Typography>
            </Box>
        </Box>
    );
}
