// Mapeamento de extensões para tipos de arquivos
const mediaTypes = {
    '.jpg': 'image',
    '.jpeg': 'image',
    '.png': 'image',
    '.gif': 'image',
    '.bmp': 'image',
    '.svg': 'image',
    '.webp': 'image',
    '.tiff': 'image',
    '.ico': 'image',

    '.mp4': 'video',
    '.mov': 'video',
    '.avi': 'video',
    '.mkv': 'video',
    '.webm': 'video',
    '.flv': 'video',
    '.wmv': 'video',
    '.3gp': 'video',
    '.m4v': 'video',

    '.mp3': 'audio',
    '.wav': 'audio',
    '.ogg': 'audio',
    '.flac': 'audio',
    '.aac': 'audio',
    '.m4a': 'audio',
    '.wma': 'audio',
    '.amr': 'audio',
};

function detectMediaType(url) {
    const extension = url.slice(url.lastIndexOf('.')).toLowerCase();
    return mediaTypes[extension] || 'document';
}

export default detectMediaType;

