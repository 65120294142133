import { isString } from 'lodash';
import { toast } from 'react-toastify';
import { i18n } from '../translate/i18n';

const toastError = (err, time = 2000) => {
    const errorMsg = err?.response?.data?.message || err?.response?.data?.error;
    if (errorMsg) {
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            toast.error(i18n.t(`backendErrors.${errorMsg}`), {
                toastId: errorMsg,
                autoClose: time,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        } else {
            toast.error(errorMsg, {
                toastId: errorMsg,
                autoClose: time,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }
    }
    console.error(err);
    if (isString(err)) {
        toast.error(err);
        return;
    } else {
        toast.error('An error occurred!');
        return;
    }
};

export default toastError;
