import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close,
    AttachFile,
    NavigateNext,
    NavigateBefore,
    Send,
    Delete,
    Description,
} from '@material-ui/icons';

import toastError from '../../errors/toastError';
import api from '../../services/api';
import CircularProgress from '../CircularProgress';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: 1000,
            height: '90vh',
            margin: 16,
            borderRadius: 3,
            backgroundColor: '#222E35',
            color: 'white',
        },
    },
    header: {
        backgroundColor: theme.palette.type === 'dark' ? '#666' : 'rgb(241 241 241)',
        color: theme.palette.type === 'dark' ? '#D9DEE0' : '#000000',
        padding: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 64px)',
        padding: '0 !important',
        position: 'relative',
        backgroundColor: theme.palette.type === 'dark' ? '#222222' : '#cfcfcf',
    },
    previewArea: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    navigationButton: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        padding: theme.spacing(2),
        zIndex: 2,
    },
    prevButton: {
        left: theme.spacing(2),
    },
    nextButton: {
        right: theme.spacing(2),
    },
    imagePreview: {
        maxWidth: '100%',
        maxHeight: 'calc(90vh - 200px)',
        objectFit: 'contain',
        transition: 'transform 0.3s ease',
    },
    videoPreview: {
        maxWidth: '100%',
        maxHeight: 'calc(90vh - 200px)',
        objectFit: 'contain',
    },
    audioPreview: {
        width: '100%',
    },
    pdfPreview: {
        width: '100%',
        height: 'calc(90vh - 100px)',
    },
    filePreview: {
        padding: theme.spacing(3),
        backgroundColor: '#2A3942',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        maxWidth: 400,
    },
    fileIcon: {
        fontSize: 48,
        color: '#85959F',
    },
    fileInfo: {
        flex: 1,
    },
    fileName: {
        color: '#E9EDEF',
        fontWeight: 500,
        marginBottom: theme.spacing(0.5),
    },
    fileSize: {
        color: '#8696A0',
        fontSize: '0.875rem',
    },
    inputArea: {
        backgroundColor: theme.palette.type === 'dark' ? '#666' : 'rgb(241 241 241)',
        padding: theme.spacing(2),
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'flex-end',
    },
    commentField: {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.type === 'dark' ? '#222' : '#cfcfcf',
            borderRadius: 8,
            color: theme.palette.type === 'dark' ? '#FFFFFF' : '#000000',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00A884',
            },
        },
        '& .MuiOutlinedInput-input': {
            '&::placeholder': {
                color: '#8696A0',
                opacity: 1,
            },
        },
    },
    sendButton: {
        backgroundColor: '#00A884',
        color: 'white',
        '&:hover': {
            backgroundColor: '#008F72',
        },
        minWidth: 'unset',
        padding: theme.spacing(1.5),
        borderRadius: '50%',
    },
    sendButtonLoading: {
        backgroundColor: '#00A884',
        color: 'white',
        '&:hover': {
            backgroundColor: '#008F72',
        },
        minWidth: 'unset',
        padding: '0px',
        borderRadius: '50%',
    },
    pagination: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        padding: theme.spacing(0.5, 2),
        borderRadius: 12,
        color: 'white',
    },
    deleteButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    },
    attachButton: {
        color: '#8696A0',
        '&:hover': {
            backgroundColor: 'rgba(134, 150, 160, 0.1)',
        },
    },
}));

const FilePreviewDialog = ({
    open,
    setOpen,
    medias,
    setMedias,
    ticketId,
    disabled
}) => {

    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [sendingNumber, setSendingNumber] = useState(0);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (medias && typeof medias === 'object' && Object.keys(medias).length > 0) {
            const mediaArray = Object.values(medias).map(media => new File([media], media.name, { type: media.type }));
            const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB
            const validFiles = mediaArray.filter((media) => {
                if (media.size > MAX_FILE_SIZE) {
                    toastError(
                        `O arquivo ${media.name || ""} é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`
                    );
                    return false;
                }
                return true;
            });

            const filesWithPreviews = validFiles.map(media => ({
                file: media,
                id: Math.random().toString(36).substring(7),
                comment: '',
                preview: media.type.startsWith('image/') ? URL.createObjectURL(media) :
                    media.type === 'application/pdf' ? URL.createObjectURL(media) :
                        media.type.startsWith('video/') ? URL.createObjectURL(media) :
                            media.type.startsWith('audio/') ? URL.createObjectURL(media) : null,
            }));
            setFiles(filesWithPreviews);
        }
    }, [medias]);

    const handleFileSelect = async (event) => {
        setOpen(true);
        const newFiles = Array.from(event.target.files);
        const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB
        const validFiles = newFiles.filter((file) => {
            if (file.size > MAX_FILE_SIZE) {
                toastError(
                    `O arquivo ${file.name || ""} é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`
                );
                return false;
            }
            return true;
        });

        setLoading(true);

        const filesWithPreviews = await Promise.all(validFiles.map(async (file) => ({
            file,
            id: Math.random().toString(36).substring(7),
            comment: '',
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) :
                file.type === 'application/pdf' ? URL.createObjectURL(file) :
                    file.type.startsWith('video/') ? URL.createObjectURL(file) :
                        file.type.startsWith('audio/') ? URL.createObjectURL(file) : null,
        })));

        setFiles(prev => [...prev, ...filesWithPreviews]);
        setLoading(false);
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleClose = () => {
        setOpen(false);
        files.forEach(file => {
            if (file.preview) {
                URL.revokeObjectURL(file.preview);
            }
        });
        setFiles([]);
        setMedias([]);
        setCurrentIndex(0);
        setLoading(false);
        setLoadingSend(false);
        setSendingNumber(0);
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleNavigate = (direction) => {
        setCurrentIndex(prev => {
            if (direction === 'next') {
                return prev < files.length - 1 ? prev + 1 : prev;
            } else {
                return prev > 0 ? prev - 1 : prev;
            }
        });
    };

    const handleDeleteCurrent = () => {
        const fileToDelete = files[currentIndex];
        if (fileToDelete.preview) {
            URL.revokeObjectURL(fileToDelete.preview);
        }

        setFiles(prev => prev.filter((_, index) => index !== currentIndex));

        if (currentIndex === files.length - 1) {
            setCurrentIndex(prev => Math.max(0, prev - 1));
        }

        if (files.length === 1) {
            handleClose();
        }
    };

    const handleCommentChange = (newComment) => {
        setFiles(prev =>
            prev.map((file, index) =>
                index === currentIndex ? { ...file, comment: newComment } : file
            )
        );
    };

    const currentFile = files[currentIndex];

    const handleSend = async (e) => {
        e.preventDefault();
        try {
            setLoadingSend(true);
            setSendingNumber(0);
            for (let i = 0; i < files.length; i++) {
                const { file, comment } = files[i];
                const formData = new FormData();
                formData.append('fromMe', true);
                formData.append('medias', file);
                formData.append('body', comment);
                await api.post(`/messages/${ticketId}`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    },
                });
                setSendingNumber(i + 1);
                await new Promise(resolve => setTimeout(resolve, 1500));
            }
        } catch (err) {
            toastError(err);
        } finally {
            setTimeout(() => {
                setLoadingSend(false);
                setFiles([]);
                handleClose();
            }, 1000);
        }
    };

    useEffect(() => {
        return () => {
            handleClose();
        };
    }, []);

    return (
        <>
            <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                multiple
                onChange={handleFileSelect}
                ref={fileInputRef}
            />
            <label htmlFor="file-input">
                <IconButton disabled={disabled} className={classes.attachButton} component="span">
                    <AttachFile />
                </IconButton>
            </label>

            <Dialog open={open} className={classes.dialogRoot}>
                {loading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <>
                        <div className={classes.header}>
                            <div className={classes.headerInfo}>
                                <Typography variant="h6">
                                    {files.length > 1 ? 'Arquivos' : 'Arquivo'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {currentIndex + 1} de {files.length}
                                </Typography>
                            </div>
                            <IconButton onClick={handleClose} color="inherit">
                                <Close />
                            </IconButton>
                        </div>

                        <DialogContent className={classes.content}>
                            {files.length > 0 && (
                                <>
                                    <div className={classes.previewArea}>
                                        {files.length > 1 && currentIndex > 0 && (
                                            <IconButton
                                                className={`${classes.navigationButton} ${classes.prevButton}`}
                                                onClick={() => handleNavigate('prev')}
                                            >
                                                <NavigateBefore />
                                            </IconButton>
                                        )}
                                        {currentFile.preview ? (
                                            currentFile.file.type.startsWith('image/') ? (
                                                <img
                                                    src={currentFile.preview}
                                                    alt="Preview"
                                                    className={classes.imagePreview}
                                                />
                                            ) : currentFile.file.type.startsWith('video/') ? (
                                                <video
                                                    controls
                                                    className={classes.videoPreview}
                                                >
                                                    <source src={currentFile.preview} />
                                                </video>
                                            ) : currentFile.file.type.startsWith('audio/') ? (
                                                <audio controls className={classes.audioPreview}>
                                                    <source src={currentFile.preview} />
                                                </audio>
                                            ) : currentFile.file.type === 'application/pdf' ? (
                                                <iframe
                                                    src={currentFile.preview}
                                                    title="PDF Preview"
                                                    style={{
                                                        width: '100%',
                                                        height: 'calc(90vh - 200px)',
                                                        border: 'none',
                                                    }}
                                                />
                                            ) : (
                                                <div className={classes.filePreview}>
                                                    <Description className={classes.fileIcon} />
                                                    <div className={classes.fileInfo}>
                                                        <Typography className={classes.fileName}>
                                                            {currentFile.file.name}
                                                        </Typography>
                                                        <Typography className={classes.fileSize}>
                                                            {formatFileSize(currentFile.file.size)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className={classes.filePreview}>
                                                <Description className={classes.fileIcon} />
                                                <div className={classes.fileInfo}>
                                                    <Typography className={classes.fileName}>
                                                        {currentFile.file.name}
                                                    </Typography>
                                                    <Typography className={classes.fileSize}>
                                                        {formatFileSize(currentFile.file.size)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}

                                        {files.length > 1 && currentIndex < files.length - 1 && (
                                            <IconButton
                                                className={`${classes.navigationButton} ${classes.nextButton}`}
                                                onClick={() => handleNavigate('next')}
                                            >
                                                <NavigateNext />
                                            </IconButton>
                                        )}

                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={handleDeleteCurrent}
                                        >
                                            <Delete />
                                        </IconButton>

                                        {files.length > 1 && (
                                            <div className={classes.pagination}>
                                                {currentIndex + 1} / {files.length}
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.inputArea}>
                                        <TextField
                                            disabled={loadingSend}
                                            className={classes.commentField}
                                            multiline
                                            maxRows={4}
                                            variant="outlined"
                                            placeholder="Adicione uma legenda..."
                                            value={currentFile.comment}
                                            onChange={(e) => handleCommentChange(e.target.value)}
                                        />
                                        <IconButton
                                            className={loadingSend ? classes.sendButtonLoading : classes.sendButton}
                                            onClick={handleSend}
                                            disabled={loadingSend}
                                        >
                                            {loadingSend ? (
                                                <>
                                                    <CircularProgress
                                                        current={sendingNumber}
                                                        total={files.length}
                                                    />
                                                </>
                                            ) : (
                                                <Send />
                                            )}
                                        </IconButton>
                                    </div>
                                </>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog >
        </>
    );
};

export default FilePreviewDialog;
