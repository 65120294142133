import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OtpInput from '../OtpInput';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import api from '../../services/api';
import { toast } from 'react-toastify';
import toastError from "../../errors/toastError";

const steps = ['Gerar token', 'Confirmar token', 'Acessar'];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    btnWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    boldText: {
        fontWeight: 'bold',
        marginLeft: '4px',
    },
    boldTextClick: {
        fontWeight: 'bold',
        color: '#3287C9',
        marginLeft: '4px',
        cursor: 'pointer'
    },
    successIcon: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    emailIcon: {
        marginRight: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogTitle: {
        position: 'relative',
    },
    dialogContent: {
        textAlign: 'center',
    },
    actionsButtons: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
}));

export default function SupportTokenModal({ open, onClose, onConfirm }) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const [otpValue, setOtpValue] = React.useState(Array(8).fill(""));
    const [isValidToken, setIsValidToken] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchParam, setSearchParam] = React.useState("");
    const [value, setValue] = React.useState(null);

    const handleClose = () => {
        onClose(false);
        setActiveStep(0);
        setOtpValue(Array(8).fill(""));
        setIsValidToken(true);
        setCompanies([]);
        setLoading(false);
        setSearchParam("");
        setValue(null);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleGenerateToken = async () => {
        setLoading(true);
        setOtpValue(Array(8).fill(""));
        try {
            await api.post('/support/generate-token/', {
                params: { email: 'suporte@softlogsoftware.com.br' },
            });
            setActiveStep(1)
        } catch (error) {
            console.error(error);
            toastError(error);
        } finally {
            console.info('generate-token');
            setLoading(false);
        }
    };

    const handleSendToken = async () => {
        setLoading(true);
        setIsValidToken(true);
        try {
            const { data } = await api.post('/support/check-token/', {
                params: { email: 'suporte@softlogsoftware.com.br', token: otpValue.join('') },
            });
            setIsValidToken(data?.isValid || false)
            if (data?.isValid) {
                handleListCompanies();
                setActiveStep(2);
            };
        } catch (error) {
            console.error(error);
            toastError(error);
        } finally {
            console.info('check-token');
            setLoading(false);
        }
    };

    const handleListCompanies = async () => {
        setLoading(true);
        try {
            const { data } = await api.post('/support/list-company/', {
                params: { email: 'suporte@softlogsoftware.com.br', token: otpValue.join('') },
            });
            setCompanies(data)

        } catch (error) {
            console.error(error);
            toastError(error);
        } finally {
            console.info('list-company');
            setLoading(false);
        }
    };

    const handleLoginSupport = async () => {
        if (!value?.id) toast.error("Selecione uma empresa");
        console.info({ email: 'suporte@softlogsoftware.com.br', token: otpValue.join(''), companyId: value.id })
        setLoading(true);
        try {
            await api.post('/support/change-company/', {
                params: {
                    email: 'suporte@softlogsoftware.com.br',
                    token: otpValue.join(''),
                    companyId: value.id
                },
            });
            await onConfirm({
                email: 'suporte@softlogsoftware.com.br',
                token: otpValue.join('')
            })
        } catch (error) {
            toastError(error);
        } finally {
            console.info('change-company');
            setLoading(false);
        }

    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            onInteractOutside={(e) => {
                e.preventDefault();
            }}
            BackdropProps={{ classes: { root: classes.backDrop } }}
            aria-labelledby="support-dialog">
            <DialogTitle id="support-dialog" className={classes.dialogTitle}>
                {'Logando como suporte'}
            </DialogTitle>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/* Passo 1 */}
                        <Box style={activeStep === 0 ? { padding: '25px' } : { display: 'none' }}>
                            <Typography variant="body1">
                                {'Para acessar esta conta, é necessário gerar um token de acesso que será enviado para o seu e-mail.'}
                            </Typography>
                            <Box mt={2}>
                                <Button
                                    fullWidth
                                    onClick={() => handleGenerateToken()}
                                    color="primary"
                                    disabled={loading}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                    startIcon={<EmailIcon />}
                                >
                                    Gerar Token
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </Box>
                        </Box>

                        {/* Passo 2 */}
                        <Box style={activeStep === 1 ? { padding: '25px' } : { display: 'none' }}>
                            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                <Typography variant="body1">
                                    Enviamos o token de acesso para o e-mail<span className={classes.boldText}>s******@s**********ware.com.br</span> para liberar seu acesso.
                                </Typography>
                            </Box>
                            <Box>
                                <OtpInput length={8} isSplit otp={otpValue} setOtp={setOtpValue} />
                            </Box>
                            {(!isValidToken) && (
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            color: '#f86363',
                                            marginTop: '8px'
                                        }}>
                                        Token de acesso inválido, tente novamente ou gere outro token de acesso.
                                    </Typography>
                                </Box>
                            )}
                            <Box style={{ marginTop: '10px' }}>
                                <Button
                                    fullWidth
                                    onClick={() => handleSendToken()}
                                    color="primary"
                                    disabled={loading}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    Verificar token e continuar
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </Box>
                            <Box>
                                <Typography variant="body1">Não recebeu? Tente<span className={classes.boldTextClick} onClick={() => handleGenerateToken()}>enviar novamente</span>.</Typography>
                            </Box>
                        </Box>

                        {/* Passo 3 - Autocomplete */}
                        <Box style={activeStep === 2 ? { padding: '25px' } : { display: 'none' }}>
                            <Autocomplete
                                size="small"
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                options={companies} // Usando as empresas listadas
                                getOptionLabel={(option) => option.name} // Atribuir o campo que deve aparecer
                                onInputChange={(event, newInputValue) => {
                                    setSearchParam(newInputValue); // Atualiza searchParam ao digitar
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Buscar Empresa" variant="outlined" />
                                )}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                            <Button
                                color="inherit"
                                variant="contained"
                                disabled={loading}
                                onClick={() => handleClose()}
                                sx={{ mr: 1 }}
                            >
                                Cancelar
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button disabled={activeStep == 1} variant="contained" onClick={() => activeStep === steps.length - 1 ? handleLoginSupport() : handleNext()}>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                {activeStep === steps.length - 1 ? 'Finalizar e acessar' : 'Próximo'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Dialog >
    );
}