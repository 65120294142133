
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControl, Input, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { GetApp } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import toastError from '../../errors/toastError';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        borderRadius: 0,
        height: '100%',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
    messageList: {
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
        ...theme.scrollbarStyles,
        backgroundColor: theme.mode === 'light' ? '#eee' : 'initial',
    },
    inputArea: {
        position: 'relative',
        height: 'auto',
    },
    input: {
        padding: '20px',
        backgroundColor: theme.mode === 'light' ? 'initial' : '#666',
    },
    buttonSend: {
        margin: theme.spacing(1),
    },
    boxLeft: {
        padding: '10px 10px 5px',
        margin: '10px',
        position: 'relative',
        backgroundColor: theme.mode === 'light' ? 'white' : '#666',
        maxWidth: 360,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    boxRight: {
        padding: '10px 10px 5px',
        margin: '10px 10px 10px auto',
        position: 'relative',
        backgroundColor: '#075E54',
        color: 'white',
        textAlign: 'right',
        maxWidth: 360,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    uploadInput: {
        display: 'none',
    },
    downloadMedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        padding: 10,
    },
    messageMedia: {
        objectFit: 'cover',
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const ChatInput = ({ handleSendMessage }) => {
    const classes = useStyles();

    const [contentMessage, setContentMessage] = useState('');
    const [medias, setMedias] = useState([]);


    const FileInput = (props) => {
        const { handleChangeMedias, disableOption } = props;
        const classes = useStyles();

        const handleFileChange = (event) => {
            const files = Array.from(event.target.files);

            const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB

            const validFiles = files.filter((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    toastError(
                        `O arquivo selecionado é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`
                    );
                    return false;
                }
                return true;
            });

            if (validFiles.length > 0 && handleChangeMedias) {
                // Chamar a função com os arquivos válidos
                handleChangeMedias({ target: { files: validFiles } });
            }
        };

        return (
            <>
                <input
                    multiple
                    type="file"
                    id="upload-button"
                    disabled={disableOption()}
                    className={classes.uploadInput}
                    onChange={handleFileChange}
                />
                <label htmlFor="upload-button">
                    <IconButton aria-label="upload" component="span" disabled={disableOption()}>
                        <AttachFileIcon className={classes.sendMessageIcons} />
                    </IconButton>
                </label>
            </>
        );
    };

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    return (
        <div className={classes.inputArea}>
            <FormControl variant="outlined" fullWidth>
                <Input
                    disabled={medias?.length > 0}
                    multiline
                    value={medias?.length > 0 ? medias[0]?.name : contentMessage}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' && contentMessage.trim() !== '' || medias?.length > 0) {
                            handleSendMessage({ medias: medias, message: contentMessage });
                            setContentMessage('');
                            setMedias(null);
                        }
                    }}
                    onChange={(e) => setContentMessage(e.target.value)}
                    className={classes.input}
                    startAdornment={
                        <InputAdornment position="end">
                            {medias?.length > 0 ?
                                (
                                    <IconButton
                                        onClick={() => setMedias(null)}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                )
                                :
                                (<FileInput handleChangeMedias={handleChangeMedias} disableOption={() => false} />)
                            }
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    if (contentMessage.trim() !== '' || medias?.length > 0) {
                                        handleSendMessage({ medias: medias, message: contentMessage });
                                        setContentMessage('');
                                        setMedias(null);
                                    }
                                }}
                                className={classes.buttonSend}
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    );
};

export default ChatInput;