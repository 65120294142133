import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AccountCircle from '@material-ui/icons/AccountCircle';
import CachedIcon from '@material-ui/icons/Cached';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';

import AnnouncementsPopover from '../components/AnnouncementsPopover';
import BackdropLoading from '../components/BackdropLoading';
import NotificationsPopOver from '../components/NotificationsPopOver';
import NotificationsVolume from '../components/NotificationsVolume';
import UserModal from '../components/UserModal';
import { AuthContext } from '../context/Auth/AuthContext';
import toastError from '../errors/toastError';
import { i18n } from '../translate/i18n';
import MainListItems from './MainListItems';

import logoDark from '../assets/logo-dark.png';
import logo from '../assets/logo.png';
import ChatPopover from '../pages/Chat/ChatPopover';

import { useDate } from '../hooks/useDate';

import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import BusinessIcon from '@material-ui/icons/Business';
import socket from '../hooks/useSocket';
import ColorModeContext from '../layout/themeContext';
import ChangeCompanyModal from '../components/ChangeCompanyModal';
import browserInfo from '../utils/browserInfo';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 56px)',
        },
        backgroundColor: theme.mode === 'light' ? '#FFF' : 'rgb(66 66 66)',
        '& .MuiButton-outlinedPrimary': {
            color: theme.mode === 'light' ? '#3287C9' : '#FFF',
            border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: theme.mode === 'light' ? '#3287C9' : '#FFF',
        },
    },
    avatar: {
        width: '100%',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: theme.palette.dark.main,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        minHeight: '48px',
        [theme.breakpoints.down('sm')]: {
            height: '48px',
        },
    },

    appBar: {
        ...(theme.mode === 'dark' && { backgroundColor: 'rgb(66 66 66)' }),
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        color: 'white',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        ...theme.scrollbarStylesSoft,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    appBarSpacer: {
        minHeight: '48px',
    },
    content: {
        flex: 1,
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    containerWithScroll: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    NotificationsPopOver: {
        // color: theme.barraSuperior.secondary.main,
    },
    logo: {
        width: '80%',
        height: 'auto',
        maxWidth: 180,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: '80%',
            maxWidth: 180,
        },
        logo: theme.logo,
    },
}));

const LoggedInLayout = ({ children }) => {
    const classes = useStyles();
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [companiesModalOpen, setCompaniesModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { handleLogout, loading } = useContext(AuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerVariant, setDrawerVariant] = useState('permanent');
    // const [dueDate, setDueDate] = useState("");
    const { user, handleStatus } = useContext(AuthContext);

    const theme = useTheme();
    const { colorMode } = useContext(ColorModeContext);
    const greaterThenSm = useMediaQuery(theme.breakpoints.up('sm'));

    const [volume, setVolume] = useState(localStorage.getItem('volume') || 1);

    const statusRef = useRef(0);
    const activityTimeout = useRef(null);
    const { dateToClient } = useDate();

    useEffect(() => {
        if (document.body.offsetWidth > 600) {
            setDrawerOpen(true);
        }
    }, []);

    useEffect(() => {
        if (document.body.offsetWidth < 600) {
            setDrawerVariant('temporary');
        } else {
            setDrawerVariant('permanent');
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (!user?.companyId) return;

        const companyId = localStorage.getItem('companyId');
        const userId = localStorage.getItem('userId');

        socket.connect(user.companyId);

        socket.on(`company-${companyId}-auth`, (data) => {
            if (data.user.id === +userId) {
                toastError('Sua conta foi acessada em outro computador.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.reload();
                }, 1000);
            }
        });

        const handleActivity = () => {
            if (activityTimeout.current) clearTimeout(activityTimeout.current);

            if (statusRef.current !== 1) {
                socket.emit('change_status', { userId: user.id, online: 1 });
                statusRef.current = 1;
            }

            activityTimeout.current = setTimeout(
                () => {
                    if (statusRef.current !== 2) {
                        socket.emit('change_status', { userId: user.id, online: 2 });
                        statusRef.current = 2;
                    }
                },
                5 * 60 * 1000
            ); // Usuário fica ausente após 5 minutos de inatividade
        };

        socket.on('connect', () => {
            const browser = browserInfo();
            socket.emit('register', { userId: user.id, browser });
            handleActivity();
        });

        socket.on('disconnect', () => {
            if (activityTimeout.current) clearTimeout(activityTimeout.current);
            statusRef.current = 0;
        });

        socket.on(`user_status_${user.companyId}`, (data) => {
            console.info(
                `Usuário: [${data.id}]:[${data.name}] está ${data.online === 0 ? 'OFFLINE' : data.online === 1 ? 'ONLINE' : 'AUSENTE'}`
            );
            if (data.id === user.id) {
                handleStatus(data.online);
            }
        });

        const handleMouseMove = () => handleActivity();
        const handleKeyDown = () => handleActivity();
        const handleBeforeUnload = () => socket.emit('change_status', { userId: user.id, online: 1 });

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // socket.disconnect();
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [user]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
        handleCloseMenu();
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        handleLogout();
    };

    const drawerClose = () => {
        if (document.body.offsetWidth < 600) {
            setDrawerOpen(false);
        }
    };

    const handleRefreshPage = () => {
        window.location.reload(false);
    };

    const toggleColorMode = () => {
        colorMode.toggleColorMode();
    };

    const handleChangeCompany = () => {
        setCompaniesModalOpen(true);
    };

    if (loading) {
        return <BackdropLoading />;
    }

    return (
        <div className={classes.root}>
            <ChangeCompanyModal open={companiesModalOpen} onClose={setCompaniesModalOpen} onConfirm={console.info} />
            <Drawer
                variant={drawerVariant}
                className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
                classes={{
                    paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                }}
                open={drawerOpen}
            >
                <div className={classes.toolbarIcon}>
                    <img src={theme.mode === 'dark' ? logoDark : logo} className={classes.logo} alt="logo" />
                    <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List className={classes.containerWithScroll}>
                    <MainListItems drawerClose={drawerClose} collapsed={!drawerOpen} />
                </List>
                <Divider />
            </Drawer>
            <UserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} userId={user?.id} />
            <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        variant="contained"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                        {/* {greaterThenSm && user?.profile === "admin" && getDateAndDifDays(user?.company?.dueDate).difData < 7 ? ( */}
                        {greaterThenSm && user?.profile === 'admin' && user?.company?.dueDate ? (
                            <>
                                Olá <b>{user.name}</b>, Bem-vindo à <b>{user?.company?.name}</b>! (Ativo até{' '}
                                {dateToClient(user?.company?.dueDate)})
                            </>
                        ) : (
                            <>
                                Olá <b>{user.name}</b>, Bem-vindo à <b>{user?.company?.name}</b>!
                            </>
                        )}
                    </Typography>

                    {/* DESABILITADO POIS TEM BUGS */}
                    {/* <UserLanguageSelector /> */}

                    <IconButton edge="start" onClick={toggleColorMode}>
                        {theme.mode === 'dark' ? (
                            <Brightness7Icon style={{ color: 'white' }} />
                        ) : (
                            <Brightness4Icon style={{ color: 'white' }} />
                        )}
                    </IconButton>

                    <NotificationsVolume setVolume={setVolume} volume={volume} />

                    <IconButton
                        onClick={handleRefreshPage}
                        aria-label={i18n.t('mainDrawer.appBar.refresh')}
                        color="inherit"
                    >
                        <CachedIcon style={{ color: 'white' }} />
                    </IconButton>

                    {/* <DarkMode themeToggle={themeToggle} /> */}

                    {user.id && <NotificationsPopOver volume={volume} />}

                    <AnnouncementsPopover />

                    <ChatPopover />

                    {user?.email == 'suporte@softlogsoftware.com.br' && (
                        <IconButton onClick={handleChangeCompany}>
                            <BusinessIcon style={{ color: 'white' }} />
                        </IconButton>
                    )}

                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            variant="contained"
                            style={{ color: 'white' }}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={menuOpen}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={handleOpenUserModal}>
                                {i18n.t('mainDrawer.appBar.user.profile')}
                            </MenuItem>
                            <MenuItem onClick={handleClickLogout}>{i18n.t('mainDrawer.appBar.user.logout')}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                {children ? children : null}
            </main>
        </div>
    );
};

export default LoggedInLayout;
