import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import useEmailContactFilter from '../../hooks/useEmailContactFilter';
import useEmailFilter from '../../hooks/useEmailFilter';
import { i18n } from '../../translate/i18n';
import ContactModal from '../ContactModal';
import { ContactsFilter } from '../ContactsFilter';
import { LegendContainer, LegendsContainer } from './style';
import ButtonWithSpinner from '../ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        gap: 20,
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 24,
        paddingBottom: 16,
    },
    constactContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 5,
        '& > div': {
            width: '100%',
        },
    },
    emailBodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    emailBody: {
        overflowY: 'auto',
        '& *': {
            overflowWrap: 'anywhere',
        },
    },
}));

const highlightRegexMatches = (html, regex, matchedPatterns, selectedValueToAttach) => {
    return html.replace(regex, (match) => {
        const isMatched = matchedPatterns.some((mp) => mp.value === match && mp.attached);
        const isAttaching = matchedPatterns.some((mp) => mp.value === match && mp.value === selectedValueToAttach);
        const backgroundColor = isAttaching ? 'green' : isMatched ? 'blue' : 'red';
        return `<span class="matchedPattern" style="background-color: ${backgroundColor}; font-weight: bold; color: white; padding: 1px; cursor: pointer;">${match}</span>`;
    });
};

const Legend = ({ color, label }) => (
    <LegendContainer>
        <div style={{ width: 10, height: 10, backgroundColor: color }} />
        {label}
    </LegendContainer>
);

const attachContactSchema = Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
    contactId: Yup.string().required('Campo obrigatório'),
    categoryId: Yup.string().required('Campo obrigatório'),
    verifyDaysAgo: Yup.number(),
});

const AttachContactForm = ({ email, onValueSelected, isValid, isSubmitting, touched, errors, values, setFieldValue }) => {
    const classes = useStyles();
    const [categories, setCtegories] = useState([]);
    const [openContactModal, setOpenContactModal] = useState(false);
    const { listCategories } = useEmailContactFilter();

    useEffect(() => {
        const fetchAll = async () => {
            try {
                setCtegories(await listCategories());
            } catch (err) {
                console.error(err);
                toastError(err);
            }
        };
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onValueSelected(values.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.value]);

    useEffect(() => {
        const handleClick = (e) => {
            if (e.target.classList.contains('matchedPattern')) {
                setFieldValue('value', e.target.innerText);
                onValueSelected(e.target.innerText);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [setFieldValue, onValueSelected]);

    const handleOnContactSaved = (data) => {
        setFieldValue('contactId', data.id);
        setOpenContactModal(false);
    };

    return (
        <Form style={{ width: '30%' }}>
            <Grid spacing={2} container>
                <Grid item xs={12}>
                    <h2>{i18n.t('email.modal.attachForm.title')}</h2>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel id="emailFilter-modal-form-connection-selection-label">
                            {i18n.t('email.modal.attachForm.value')}
                        </InputLabel>
                        <Field
                            as={Select}
                            label={i18n.t('email.modal.attachForm.value')}
                            placeholder={i18n.t('email.modal.attachForm.value')}
                            labelId="email-modal-attachForm-value-selection-label"
                            name="value"
                            error={touched.value && Boolean(errors.value)}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                        >
                            <MenuItem value={''}>
                                <em>Selecione</em>
                            </MenuItem>
                            {email.matchedPatterns
                                .filter((mp) => !mp.attached)
                                .map((mp) => (
                                    <MenuItem key={mp.id} value={mp.value}>
                                        {mp.value}
                                    </MenuItem>
                                ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel id="emailContactFilter-modal-form-filter-category-selection-label">
                            {i18n.t('emailContact.filter.modal.form.category')}
                        </InputLabel>
                        <Field
                            as={Select}
                            label={i18n.t('emailContact.filter.modal.form.category')}
                            placeholder={i18n.t('emailContact.filter.modal.form.category')}
                            labelId="emailContactFilter-modal-form-filter-category-selection-label"
                            name="categoryId"
                            error={touched.category && Boolean(errors.category)}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {i18n.t(`emailContact.filter.list.filter.category.name.${category.name}`)}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        as={TextField}
                        label={i18n.t('emailContact.filter.modal.form.verifyDaysAgo')}
                        name="verifyDaysAgo"
                        error={touched.verifyDaysAgo && Boolean(errors.verifyDaysAgo)}
                        helperText={
                            (touched.verifyDaysAgo && errors.verifyDaysAgo) ||
                            i18n.t('emailContact.filter.modal.form.verifyDaysAgoHelper')
                        }
                        variant="outlined"
                        margin="dense"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.constactContainer}>
                        <ContactsFilter
                            onFiltered={(value) => setFieldValue('contactId', value.id)}
                            contactId={values.contactId}
                        />
                        <Button onClick={() => setOpenContactModal(true)} size="small">
                            Novo contato?
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <ButtonWithSpinner 
                        type="submit" 
                        color="primary" 
                        variant="contained" 
                        fullWidth 
                        loading={isSubmitting}
                        disabled={!isValid || isSubmitting}
                    >
                        {i18n.t('email.modal.attachForm.buttons.attach')}
                    </ButtonWithSpinner>
                </Grid>
            </Grid>
            {openContactModal && (
                <ContactModal open={true} onSave={handleOnContactSaved} onClose={() => setOpenContactModal(false)} />
            )}
        </Form>
    );
};

const EmailModal = ({ open, onClose, email }) => {
    const classes = useStyles();
    const { save } = useEmailContactFilter();
    const { findEmail } = useEmailFilter();
    const [currentEmail, setCurrentEmail] = useState(email);
    const [body, setBody] = useState('');
    const [hasMissingMatchedPatterns, setHasMissingMatchedPatterns] = useState(false);
    const [latestVerifyDaysAgo, setLatestVerifyDaysAgo] = useState(7);
    const [selectedValueToAttach, setSelectedValueToAttach] = useState('');

    useEffect(() => {
        if (!currentEmail.matchedPatterns || currentEmail.matchedPatterns.length === 0) {
            setBody(currentEmail.bodyHtml);
            return;
        }

        const regex = new RegExp(currentEmail.matchedPatterns.map((mp) => mp.emailFilterPattern.value).join('|'), 'gi');
        const highlightedBody = highlightRegexMatches(
            currentEmail.bodyHtml,
            regex,
            currentEmail.matchedPatterns,
            selectedValueToAttach
        );
        setBody(highlightedBody);
        setHasMissingMatchedPatterns(currentEmail.matchedPatterns.some((mp) => !mp.attached));
    }, [currentEmail, selectedValueToAttach]);

    const handleAttachContact = async (values, { resetForm }) => {
        try {
            await save({
                ...values,
                emailId: email.id
            });
            setCurrentEmail(await findEmail(currentEmail.id));
            setLatestVerifyDaysAgo(values.verifyDaysAgo);
            setSelectedValueToAttach('');
            setTimeout(() => resetForm(), 100);
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth scroll="paper">
            <DialogContent className={classes.dialogContent}>
                <div
                    className={classes.emailBodyContainer}
                    style={{
                        width: hasMissingMatchedPatterns ? '70%' : 'initial',
                    }}
                >
                    <div
                        className={classes.emailBody}
                        style={{
                            color: 'initial',
                        }}
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                </div>

                {hasMissingMatchedPatterns && (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            value: '',
                            contactId: '',
                            categoryId: '',
                            verifyDaysAgo: latestVerifyDaysAgo || 7,
                        }}
                        validationSchema={attachContactSchema}
                        validateOnMount
                        validateOnBlur
                        validateOnChange
                        onSubmit={handleAttachContact}
                    >
                        {(props) => (
                            <AttachContactForm
                                {...props}
                                email={currentEmail}
                                onValueSelected={setSelectedValueToAttach}
                            />
                        )}
                    </Formik>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <LegendsContainer>
                    <Legend color="blue" label={'Vinculado'} />
                    <Legend color="red" label={'Não vinculado'} />
                    <Legend color="green" label={'Vinculando'} />
                </LegendsContainer>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    {i18n.t('email.modal.buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailModal;
