/**
 * Formata o valor em KB para o melhor formato possível (KB, MB, GB, TB).
 * @param {number} sizeInKb - O tamanho em KB.
 * @returns {string} O tamanho formatado com a unidade apropriada.
 */
const formatSizeFormat = (sizeInKb) => {
    sizeInKb = +sizeInKb; 
    // Verifica se sizeInKb é um número válido
    if (typeof sizeInKb !== 'number' || isNaN(sizeInKb)) {
        console.error('Erro: sizeInKb deve ser um número válido.');
        return 'Invalid size';
    }

    if (sizeInKb === 0) return '0 KB';

    const units = ['KB', 'MB', 'GB', 'TB'];
    let index = 0;

    // Converte o tamanho para a unidade apropriada
    while (sizeInKb >= 1024 && index < units.length - 1) {
        sizeInKb /= 1024;
        index++;
    }

    // Retorna o tamanho formatado com 2 casas decimais e a unidade
    return `${sizeInKb.toFixed(2)} ${units[index]}`;
};

export default formatSizeFormat;
