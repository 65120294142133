import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TabPanel from '../../components/TabPanel';

import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    Tab,
    Tabs,
    TextField,
} from '@material-ui/core';

import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import MoveQueue from '../MoveQueue';
import QueueSelect from '../QueueSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const SessionSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles();
    const initialState = {
        name: '',
        greetingMessage: '',
        complationMessage: '',
        outOfHoursMessage: '',
        inactivityMessage: '',
        isDefault: false,
        token: '',
        maxUseBotQueues: '0',
        provider: 'beta',
        expiresTicket: 0,
        importMessages: false,
        closeTicketsImport: false,
        importMessagesGroup: false,
        importStartDate: '',
        importEndDate: '',
        selectedMoveQueueId: '',
        selectedInterval: '',
    };
    const [whatsApp, setWhatsApp] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [selectedMoveQueueId, setSelectedMoveQueueId] = useState(null);
    const [selectedInterval, setSelectedInterval] = useState(0);
    const [importStartDate, setImportStartDate] = useState(new Date());
    const [importEndDate, setImportEndDate] = useState(new Date());
    const [tab, setTab] = useState('conexão');

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return;

            try {
                const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);

                setWhatsApp(data);

                const whatsQueueIds = data.queues?.map((queue) => queue.id);
                setSelectedMoveQueueId(data.selectedMoveQueueId);
                setSelectedInterval(data.selectedInterval);
                setSelectedQueueIds(whatsQueueIds);
            } catch (err) {
                toastError(err);
            }
        };

        fetchSession();
    }, [whatsAppId]);

    useEffect(() => {
        if (selectedMoveQueueId === '-1') {
            setSelectedInterval(0);
        }
    }, [selectedMoveQueueId]);

    const handleSaveWhatsApp = async (values) => {
        const whatsappData = {
            ...values,
            queueIds: selectedQueueIds,
            selectedMoveQueueId: selectedMoveQueueId,
            selectedInterval: selectedInterval,
        };
        delete whatsappData['queues'];
        delete whatsappData['session'];

        try {
            if (whatsAppId) {
                await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
            } else {
                await api.post('/whatsapp', whatsappData);
            }
            toast.success(i18n.t('whatsappModal.success'));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setWhatsApp(initialState);
        setSelectedInterval(0);
        setSelectedMoveQueueId([]);
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
                <DialogTitle>
                    {whatsAppId ? i18n.t('whatsappModal.title.edit') : i18n.t('whatsappModal.title.add')}
                </DialogTitle>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => setTab(newValue)}
                >
                    <Tab label="Conexão" value="conexão" />
                    <Tab label="Importação de Mensagens" value="importação" />
                </Tabs>
                {tab === 'conexão' && (
                    <TabPanel>
                        <Formik
                            initialValues={whatsApp}
                            enableReinitialize={true}
                            validationSchema={SessionSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    handleSaveWhatsApp(values);
                                    actions.setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ values, touched, errors, isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>
                                        {/* NOME E PADRAO */}
                                        <div className={classes.multFieldLine}>
                                            <Grid spacing={2} container>
                                                <Grid item>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t('whatsappModal.form.name')}
                                                        autoFocus
                                                        name="name"
                                                        error={touched.name && Boolean(errors.name)}
                                                        helperText={touched.name && errors.name}
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={classes.textField}
                                                    />
                                                </Grid>
                                                <Grid style={{ paddingTop: 15 }} item>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                as={Switch}
                                                                color="primary"
                                                                name="isDefault"
                                                                checked={values.isDefault}
                                                            />
                                                        }
                                                        label={i18n.t('whatsappModal.form.default')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        {/* MENSAGEM DE SAUDAÇÃO */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.greetingMessage')}
                                                type="greetingMessage"
                                                multiline
                                                minRows={4}
                                                fullWidth
                                                name="greetingMessage"
                                                error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                                                helperText={touched.greetingMessage && errors.greetingMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        {/* MENSAGEM DE CONCLUSÃO */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.complationMessage')}
                                                type="complationMessage"
                                                multiline
                                                minRows={4}
                                                fullWidth
                                                name="complationMessage"
                                                error={touched.complationMessage && Boolean(errors.complationMessage)}
                                                helperText={touched.complationMessage && errors.complationMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        {/* MENSAGEM DE FORA DE EXPEDIENTE */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.outOfHoursMessage')}
                                                type="outOfHoursMessage"
                                                multiline
                                                minRows={4}
                                                fullWidth
                                                name="outOfHoursMessage"
                                                error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
                                                helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        {/* MENSAGEM DE INATIVIDADE */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.inactivityMessage')}
                                                type="inactivityMessage"
                                                multiline
                                                minRows={4}
                                                fullWidth
                                                name="inactivityMessage"
                                                error={touched.inactivityMessage && Boolean(errors.inactivityMessage)}
                                                helperText={touched.inactivityMessage && errors.inactivityMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        {/* TOKEN */}

                                        {whatsAppId && (
                                            <div>
                                                <Field
                                                    disabled
                                                    as={TextField}
                                                    label={i18n.t('whatsappModal.form.token')}
                                                    type="token"
                                                    fullWidth
                                                    name="token"
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </div>)
                                        }

                                        {/* QUANTIDADE MÁXIMA DE VEZES QUE O CHATBOT VAI SER ENVIADO */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.maxUseBotQueues')}
                                                type="maxUseBotQueues"
                                                fullWidth
                                                name="maxUseBotQueues"
                                                variant="outlined"
                                                margin="dense"
                                                error={touched.maxUseBotQueues && Boolean(errors.maxUseBotQueues)}
                                                helperText={touched.maxUseBotQueues && errors.maxUseBotQueues}
                                            />
                                        </div>

                                        {/* ENCERRAR CHATS ABERTOS APÓS X HORAS */}
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t('whatsappModal.form.expiresTicket')}
                                                type="expiresTicket"
                                                fullWidth
                                                name="expiresTicket"
                                                variant="outlined"
                                                margin="dense"
                                                error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                                                helperText={touched.expiresTicket && errors.expiresTicket}
                                            />
                                        </div>

                                        {/*  */}
                                        <QueueSelect
                                            selectedQueueIds={selectedQueueIds}
                                            onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                                        />
                                        <div>
                                            <strong>Redirecionamento de Fila</strong>
                                        </div>
                                        <br />
                                        <div>
                                            <strong>
                                                Selecione uma fila para os contatos que não possuem fila, serem
                                                redirecionados em X minutos
                                            </strong>
                                        </div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <MoveQueue
                                                    selectedMoveQueueId={selectedMoveQueueId}
                                                    onChange={(selectedId) => setSelectedMoveQueueId(selectedId)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t('Redirecionar para fila X em minutos')}
                                                    name="redirectAfterMinutes"
                                                    type="text"
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    value={selectedInterval}
                                                    onChange={(e) => setSelectedInterval(e.target.value)}
                                                    style={{ marginTop: 15 }}
                                                    disabled={selectedMoveQueueId === '-1'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t('whatsappModal.buttons.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {whatsAppId
                                                ? i18n.t('whatsappModal.buttons.okEdit')
                                                : i18n.t('whatsappModal.buttons.okAdd')}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </TabPanel>
                )}
                {tab === 'importação' && (
                    <TabPanel>
                        <Formik
                            initialValues={whatsApp}
                            enableReinitialize={true}
                            validationSchema={SessionSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    handleSaveWhatsApp(values);
                                    actions.setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <DialogContent dividers>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="importMessages"
                                                    checked={values.importMessages}
                                                    onChange={(e) => setFieldValue('importMessages', e.target.checked)}
                                                />
                                            }
                                            label="Importação de Mensagens"
                                        />
                                        {values.importMessages && (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            as={Switch}
                                                            color="primary"
                                                            name="importMessagesGroup"
                                                            checked={values.importMessagesGroup}
                                                            onChange={(e) =>
                                                                setFieldValue('importMessagesGroup', e.target.checked)
                                                            }
                                                        />
                                                    }
                                                    label="Importar mensagens de grupo"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            as={Switch}
                                                            color="primary"
                                                            name="closeTicketsImport"
                                                            checked={values.closeTicketsImport}
                                                            onChange={(e) =>
                                                                setFieldValue('closeTicketsImport', e.target.checked)
                                                            }
                                                        />
                                                    }
                                                    label="Encerrar tickets após importação"
                                                />
                                                <Grid container spacing={2}>
                                                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <Field
                                                                as={TextField}
                                                                label="Data de Início da Importação"
                                                                name="importStartDate"
                                                                InputLabelProps={{ shrink: true }}
                                                                type="datetime-local"
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                value={importStartDate}
                                                                onChange={(e) => setImportStartDate(e.target.value)}
                                                                onBlur={(e) => {
                                                                    const timestamp = Math.floor(
                                                                        Date.parse(e.target.value) / 1000
                                                                    );
                                                                    setFieldValue('importStartDate', timestamp);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <Field
                                                                as={TextField}
                                                                label="Data de Fim da Importação"
                                                                InputLabelProps={{ shrink: true }}
                                                                name="importEndDate"
                                                                type="datetime-local"
                                                                variant="outlined"
                                                                margin="dense"
                                                                fullWidth
                                                                value={importEndDate}
                                                                onChange={(e) => setImportEndDate(e.target.value)}
                                                                onBlur={(e) => {
                                                                    const timestamp = Math.floor(
                                                                        Date.parse(e.target.value) / 1000
                                                                    );
                                                                    setFieldValue('importEndDate', timestamp);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t('whatsappModal.buttons.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {whatsAppId
                                                ? i18n.t('whatsappModal.buttons.okEdit')
                                                : i18n.t('whatsappModal.buttons.okAdd')}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </TabPanel>
                )}
            </Dialog>
        </div>
    );
};

export default React.memo(WhatsAppModal);
