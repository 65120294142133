import { Box, Button, Divider, FormControl, IconButton, Input, InputAdornment, Paper, Typography, makeStyles } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { AuthContext } from '../../context/Auth/AuthContext';
import { useDate } from '../../hooks/useDate';
import api from '../../services/api';
import HandleBoxLinkPreview from '../../components/HandleBoxLinkPreview';
import toastError from '../../errors/toastError';
import detectMediaType from '../../helpers/detectMediaType';
import ModalImageCors from '../../components/ModalImageCors';
import { GetApp } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ChatInput from './ChatInput';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        borderRadius: 0,
        height: '100%',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
    messageList: {
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
        ...theme.scrollbarStyles,
        backgroundColor: theme.mode === 'light' ? '#eee' : 'initial',
    },
    inputArea: {
        position: 'relative',
        height: 'auto',
    },
    input: {
        padding: '20px',
        backgroundColor: theme.mode === 'light' ? 'initial' : '#666',
    },
    buttonSend: {
        margin: theme.spacing(1),
    },
    boxLeft: {
        padding: '10px 10px 5px',
        margin: '10px',
        position: 'relative',
        backgroundColor: theme.mode === 'light' ? 'white' : '#666',
        maxWidth: 360,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    boxRight: {
        padding: '10px 10px 5px',
        margin: '10px 10px 10px auto',
        position: 'relative',
        backgroundColor: '#075E54',
        color: 'white',
        textAlign: 'right',
        maxWidth: 360,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    uploadInput: {
        display: 'none',
    },
    downloadMedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        padding: 10,
    },
    messageMedia: {
        objectFit: 'cover',
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

export default function ChatMessages({
    chat,
    messages,
    handleSendMessage,
    handleLoadMore,
    scrollToBottomRef,
    pageInfo,
    loading,
}) {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { datetimeToClient } = useDate();
    const baseRef = useRef();

    const [contentMessage, setContentMessage] = useState('');
    const [medias, setMedias] = useState([]);

    const scrollToBottom = () => {
        if (baseRef.current) {
            baseRef.current.scrollIntoView({});
        }
    };

    const unreadMessages = (chat) => {
        if (chat !== undefined) {
            const currentUser = chat.users.find((u) => u.userId === user.id);
            return currentUser.unreads > 0;
        }
        return 0;
    };

    useEffect(() => {
        if (unreadMessages(chat) > 0) {
            try {
                api.post(`/chats/${chat.id}/read`, { userId: user.id });
            } catch (err) { }
        }
        scrollToBottomRef.current = scrollToBottom;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = (e) => {
        const { scrollTop } = e.currentTarget;
        if (!pageInfo.hasMore || loading) return;
        if (scrollTop < 600) {
            handleLoadMore();
        }
    };

    const FileInput = (props) => {
        const { handleChangeMedias, disableOption } = props;
        const classes = useStyles();

        const handleFileChange = (event) => {
            const files = Array.from(event.target.files);

            const MAX_FILE_SIZE = +process.env.MAX_FILE_SIZE || 150 * 1024 * 1024; // 150MB

            const validFiles = files.filter((file) => {
                if (file.size > MAX_FILE_SIZE) {
                    toastError(
                        `O arquivo selecionado é maior que ${+process.env.MAX_FILE_SIZE || 150}MB. Por favor, selecione outro arquivo.`
                    );
                    return false;
                }
                return true;
            });

            if (validFiles.length > 0 && handleChangeMedias) {
                // Chamar a função com os arquivos válidos
                handleChangeMedias({ target: { files: validFiles } });
            }
        };

        return (
            <>
                <input
                    multiple
                    type="file"
                    id="upload-button"
                    disabled={disableOption()}
                    className={classes.uploadInput}
                    onChange={handleFileChange}
                />
                <label htmlFor="upload-button">
                    <IconButton aria-label="upload" component="span" disabled={disableOption()}>
                        <AttachFileIcon className={classes.sendMessageIcons} />
                    </IconButton>
                </label>
            </>
        );
    };

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    messages = messages.filter((chat, index, self) => index === self.findIndex((c) => c.id === chat.id));

    const RenderMediaType = ({ type, url }) => {
        if (type === 'image') {
            return <ModalImageCors imageUrl={url} />;
        }

        if (type === 'audio') {
            return (
                <audio controls>
                    <source src={url} type="audio/ogg"></source>
                </audio>
            );
        }

        if (type === 'video') {
            return <video className={classes.messageMedia} src={url} controls />;
        } else {
            return (
                <>
                    <div className={classes.downloadMedia}>
                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            target="_blank"
                            href={url}
                        >
                            Download
                        </Button>
                    </div>
                    <Divider />
                </>
            );
        }
    };

    return (
        <Paper className={classes.mainContainer}>
            <div onScroll={handleScroll} className={classes.messageList}>
                {Array.isArray(messages) &&
                    messages.map((item, key) => {
                        if (item.senderId === user.id) {
                            return (
                                <Box key={key} className={classes.boxRight}>
                                    <Typography variant="subtitle2">{item.sender.name}</Typography>
                                    {item?.linkProps && (
                                        <HandleBoxLinkPreview link={item.linkProps} fromMe={true} />
                                    )}
                                    {item?.media ?
                                        <RenderMediaType
                                            type={detectMediaType(item.media.mediaUrl)}
                                            url={item.media.mediaUrl}
                                        />
                                        :
                                        null
                                    }
                                    <Typography style={{ wordBreak: "break-word" }} variant="body2">{item.message}</Typography>
                                    <Typography variant="caption" display="block">
                                        {datetimeToClient(item.createdAt)}
                                    </Typography>
                                </Box>
                            );
                        } else {
                            return (
                                <Box key={key} className={classes.boxLeft}>
                                    <Typography variant="subtitle2">{item.sender.name}</Typography>
                                    {item?.linkProps && (
                                        <HandleBoxLinkPreview link={item.linkProps} fromMe={false} />
                                    )}
                                    <Typography style={{ wordBreak: "break-word" }} variant="body2">{item.message}</Typography>
                                    <Typography variant="caption" display="block">
                                        {datetimeToClient(item.createdAt)}
                                    </Typography>
                                </Box>
                            );
                        }
                    })}
                <div ref={baseRef}></div>
            </div>
            <ChatInput handleSendMessage={handleSendMessage} />
        </Paper>
    );
}
